import "./firebaseConfig"; // Adjust the path if necessary
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/main.css";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Import Firebase configuration to initialize Firebase

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
