<template>
  <!-- <NavBar v-if="!isAdminRoute" /> -->
  <router-view></router-view>
  <!-- <FooterComponent v-if="!isAdminRoute" /> -->
</template>

<script>
import { defineComponent } from "vue";
// import NavBar from "./components/NavBar.vue";
// import FooterComponent from "@/components/FooterComponent.vue";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "App",
  components: {
    // NavBar,
    // FooterComponent,
  },
  computed: {
    isAdminRoute() {
      const route = useRoute();
      return route.path === '/admin';
    }
  }
});
</script>

<style>
/* Your styles here */
</style>
