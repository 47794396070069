<template>
  <div class="bg1">
    <NavBar v-if="showNavBar" />
    <div class="flex-container" id="start-id">
      <div class="logo-wrapper">
        <img
          src="../assets/50ppi/Asset1.png"
          alt="Aizhak's Logo"
          class="logo"
          loading="lazy"
        />
      </div>
      <div class="spacer"></div>
      <div class="text-wrapper">
        <h1 class="line">We're Professional</h1>
        <div class="title-wrapper">
          <h1 class="static-part">Coffee</h1>
          <transition name="smoke" mode="out-in">
            <h1 class="dynamic-part" :key="currentTitle">{{ currentTitle }}</h1>
          </transition>
        </div>
        <h1 class="line second">Based in Orlando, FL</h1>

        <div class="footer-title">
          <h2 class="line-two">
            Let’s make better coffee.
          </h2>
          <!-- <h1 class="line third">FL.</h1> -->
        </div>
      </div>
      <div class="spacer"></div>
    </div>

    <InfoSection />

    <ServicesSection />
    <RetailComponent />
  </div>

  <div class="bg-white-wrapper" ref="bg2">
    <ContactComponent />
    <NewsletterComponent />
    <FooterComponent />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from "vue";
import NavBar from "../components/NavBar.vue";
import FooterComponent from "../components/FooterComponent.vue";
import InfoSection from "../components/InfoSection.vue";
import ServicesSection from "@/components/ServicesSection.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import RetailComponent from "@/components/RetailComponent.vue";
import NewsletterComponent from "@/components/NewsletterComponent.vue";
import { gsap } from "gsap";

export default defineComponent({
  name: "HomeView",
  components: {
    InfoSection,
    NewsletterComponent,
    ServicesSection,
    ContactComponent,
    RetailComponent,
    NavBar,
    FooterComponent,
  },
  data() {
    return {
      currentScrollPosition: 0,
      titles: ["Consultants", "Roasters", "Trainers", "Analysts", "Experts"],
      currentTitle: "Consultants",
      titleIndex: 0,
      changeInterval: null,
      showNavBar: true,
    };
  },
  beforeUnmount() {
    clearInterval(this.changeInterval);
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    gsap.from(".line", {
      duration: 1,
      opacity: 0,
      y: 70,
      stagger: 0.2,
      delay: 0.2,
      filter: "blur(10px)",
      ease: "power2.out",
      onComplete: () =>
        gsap.to(".line", { filter: "blur(0px)", duration: 0.5 }),
    });
    gsap.from(".title-wrapper", {
      duration: 1,
      opacity: 0,
      x: -150,
      delay: 0.7,
      filter: "blur(10px)",
      ease: "power2.out",
      onComplete: () =>
        gsap.to(".title-wrapper", { filter: "blur(0px)", duration: 0.5 }),
    });
    gsap.from(".footer-title", {
      duration: 1.5,
      opacity: 0,
      y: 70,
      delay: 1,
      filter: "blur(10px)",
      ease: "power2.out",
      onComplete: () =>
        gsap.to(".footer-title", { filter: "blur(0px)", duration: 0.5 }),
    });
    // Delay the start of the title changes by 7 seconds
    // console.log("bg2 offsetTop:", this.$refs.bg2.offsetTop);
    this.handleScroll(); // Set initial state of showNavBar
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      this.changeTitle();
      this.changeInterval = setInterval(this.changeTitle, 7000);
    }, 4000);
  },
  methods: {
    
    scrollToElement(id) {
      const element = document.getElementById(id);
      if (element) {
        this.currentScrollPosition = element.offsetTop;
      }
    },
    changeTitle() {
      this.titleIndex = (this.titleIndex + 1) % this.titles.length;
      this.currentTitle = this.titles[this.titleIndex];
    },
    handleScroll() {
      const bg2Top = this.$refs.bg2.offsetTop;
      const hideThreshold = 3700; // Set the threshold for hiding the NavBar

      // Hide the NavBar when the scroll position is beyond the hideThreshold
      // and before reaching bg2Top to handle cases where bg2Top is much further down
      this.showNavBar =
        window.scrollY < hideThreshold || window.scrollY >= bg2Top;

      // Logs the position of the viewer on the screen
      // console.log(
      //   "Scroll Y:",
      //   window.scrollY,
      //   "bg2Top:",
      //   bg2Top,
      //   "Show NavBar:",
      //   this.showNavBar
      // );
    },
  },
});
</script>

<style scoped>
.bg1 {
  background-color: #090909;
}

.bg-white-wrapper {
  background-color: #ffffff;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align children to the start */
  min-height: 100vh;
  padding: 0; /* Adjust the padding to match the navbar, if necessary */
  box-sizing: border-box;
  color: antiquewhite;
  max-width: 1200px; /* This should match the navbar's max-width */
  margin-left: auto; /* Center the container */
  margin-right: auto; /* Center the container */
}
.logo-wrapper {
  /* Adjust the width, padding, or margin to align with the navbar's first element */
  padding-left: 1rem; /* Example padding, adjust based on your navbar */
  margin-right: 2rem; /* Space between logo and text */
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center the children horizontally */
}
.spacer {
  flex: 0.25;
}

.line {
  display: flex;
  justify-content: center;
  font-size: 6rem;
  line-height: 1.2;
  font-family: "Leiko Regular";
}

.line .mtitle {
  position: absolute;
  left: 0;
  right: 0;
}

.line .second {
  display: flex;
  justify-content: start;
}

.third {
  padding-left: 16px;
  font-size: 5rem;
}

.footer-title {
  display: flex;
  justify-items: center;
}

.line-two {
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: start;
}

.static-part,
.dynamic-part {
  font-size: 6rem; /* Keep the font size consistent */
  font-family: "Leiko Regular";
}

.dynamic-part {
  margin-left: 1.5rem; /* Adjust the spacing between 'Coffee' and the dynamic title */
}

.smoke-enter-active,
.smoke-leave-active {
  transition: opacity 0.5s, transform 0.6s ease-in-out, filter 0.6s ease-in-out;
}

.smoke-enter-from,
.smoke-leave-to {
  opacity: 0;
  transform: scale(1.2);
  filter: blur(7px);
}

.smoke-enter-to,
.smoke-leave-from {
  opacity: 0.7;
  transform: scale(1);
  filter: blur(3px);
}

.title-wrapper {
  display: flex; /* Use flexbox for the title */
  justify-content: center; /* Center the title horizontally */
  align-items: baseline; /* Align 'Coffee' and the dynamic part at their baseline */
}

@keyframes smoke-in {
  0% {
    opacity: 0;
    transform: scale(0.2);
    filter: blur(4px);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}

@keyframes smoke-out {
  0% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
  100% {
    opacity: 0;
    transform: scale(0.2);
    filter: blur(4px);
  }
}

.logo {
  max-width: 90%; /* Full width of the parent container */
  height: auto; /* Maintain aspect ratio */
}
@media (max-width: 1080px) {
  .line {
    font-size: 3.5rem; /* Smaller font size for medium screens */
  }
  .second,
  .third {
    font-size: 3rem; /* Adjust font size */
  }
  .line-two {
    font-size: 1rem; /* Adjust subtitle font size */
  }
  .static-part,
  .dynamic-part {
    font-size: 3.5rem; /* Adjust dynamic part font size */
  }
}

@media (max-width: 768px) {
  .bg-white-wrapper {
    background-color: #090909;
  }
  .flex-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align items */
    margin-top: 100px;
  }

  .logo-wrapper,
  .text-wrapper {
    width: 100%; /* Full width */
    padding: 0 1rem; /* Add padding for smaller screens */
    text-align: center; /* Center-align text */
  }

  .footer-title {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }

  .line,
  .second,
  .third,
  .line-two,
  .static-part,
  .dynamic-part {
    font-size: 2.5rem; /* Reduce font size for smaller screens */
    text-align: center;
  }

  .line-two {
    font-size: 1rem; /* Adjust subtitle font size */
  }

  .title-wrapper {
    flex-direction: column; /* Stack titles vertically */
    align-items: center;
  }

  .dynamic-part {
    margin-left: 0; /* Adjust spacing */
  }

  .logo {
    display: none;
  }
}

@media (max-width: 480px) {
  .line,
  .second,
  .third,
  .line-two,
  .static-part,
  .dynamic-part {
    font-size: 3rem; /* Further reduce font size for very small screens */
    text-align: center;
  }

  .line-two {
    font-size: 0.9rem; /* Adjust subtitle font size */
  }

  .logo {
    display: none;
  }
}
</style>
