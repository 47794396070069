<template>
  <div class="wrapper">
    <div class="newsletter-container">
      <form class="newsletter-form" @submit.prevent="subscribeToNewsletter">
        <h2>Trends. News. Deals.</h2>
        <div class="action-wrapper">
          <p class="newsletter-description">JOIN OUR NEWSLETTER</p>
          <input
            class="newsletter-input"
            type="email"
            id="email"
            v-model="email"
            placeholder="Enter your Email"
            required
          />
         
        </div>
      </form>
      <p class="output-response">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { db } from "@/firebaseConfig";
import { collection, addDoc } from "firebase/firestore"; // Firestore

export default {
  name: "NewsletterComponent",
  setup() {
    const email = ref("");
    const message = ref(""); // A message to give feedback to the user

    const subscribeToNewsletter = async () => {
      if (email.value.trim() === "") {
        message.value = "Please enter a valid email address.";
        return;
      }
      try {
        await addDoc(collection(db, "subscribers"), { email: email.value });
        message.value = "Thank you for subscribing!";
        email.value = ""; // Clear the input field after successful subscription
      } catch (error) {
        console.error("Full error:", error);
        message.value = "Failed to subscribe. Please try again later.";
      }
    };

    return { email, message, subscribeToNewsletter };
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
  background-color: #090909;
  background-size: cover;
  background-position: center;
  margin: 0; /* Reset any margins */
}

.newsletter-container {
  padding: 2rem; /* Add more padding for white space */
  border-radius: 8px; /* Slightly rounded corners */
}

.newsletter-form h2 {
  color: #ffffff; /* Black color for the heading */
  font-family: "Leiko Regular"; /* Update the font-family */
  font-size: 4rem; /* Larger font size for the heading */
  margin-bottom: 1rem; /* Space below the heading */
}

.newsletter-description {
  color: #ffffff;
  font-family: "Space Grotesk Light";
  font-size: 2rem; /* Smaller font size for the description */
  margin-bottom: 2rem; /* More space before input field */
  max-width: 200px;
  margin-right: 4rem;
}

.newsletter-input {
  width: 100%;
  padding: 1rem; /* Larger padding for input */
  margin-bottom: 2rem; /* More space before the button */
  border: none; /* No border */
  border-bottom: 2px solid #1f1f1f; /* Only a bottom border */
  background-color: transparent; /* No background */
  color: #ffffff;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1rem; /* Match font size to the description */
  max-width: 350px;
  margin-top: 2rem;
}

.output-response {
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-content: center;
  opacity: 0.7;
}

.action-wrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.send-button {
  padding: 1rem 2rem; /* Larger button padding */
  border: none; /* No border */
  border-radius: 4px;
  background-color: #ffffff; /* Black background */
  color: #090909; /* White text */
  font-family: "Space Grotesk", sans-serif;
  font-size: 1rem; /* Match font size to the input */
  cursor: pointer; /* Hand cursor on hover */
  transition: background-color 0.3s; /* Smooth background transition */
}

.send-button:hover {
  background-color: #333; /* Darken button on hover */
}

@media (max-width: 768px) {
  .wrapper {
    padding: 1rem; /* Add padding on smaller screens */
  }

  .action-wrapper {
  display: flex;
  flex-direction: column;

}

.newsletter-form h2 {
  font-size: 2rem;
}

.newsletter-description {
  font-size: 0.8rem;
}

  .newsletter-container {
    padding: 2rem; /* Less padding on smaller screens */
  }
}
</style>
