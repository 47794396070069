<template>
  <div class="maximum-wrapper" id="coffeeId">
    <div v-if="isDesktop" class="desktop-layout">
      <div class="div2">
        <div class="retail-container div21">
          <section class="header-section">
            <h2>Flavors</h2>
          </section>

          <section class="retail-section">
            <div class="wheel-wrapper">
              <FlavorWheel @flavor-selected="handleFlavorSelected" />
            </div>

            <div class="title-container">
              <h2 class="sub-motto title"><span>The</span> One Off</h2>
              <h2 class="sub-motto title"><span>Coffee's Profiles</span></h2>

              <p class="sub-motto">
                Discover the various profiles that make Aizhak's coffee unique
                and explore the tastes and aromas that each type of roast we
                offer.
              </p>

              <div v-if="currentSelectedFlavor" class="flavor-details">
                <p>{{ currentSelectedFlavor.description }}</p>
                <h3 :style="{ color: currentSelectedFlavor.color }">
                  {{ currentSelectedFlavor.name }}
                </h3>
              </div>
            </div>

            <div class="retail-info"></div>
          </section>
        </div>
        <div class="div22"></div>

        <div class="div24"></div>
        <div class="div2mobile"></div>
      </div>
      <div class="div3">
        <div class="content-wrapper">
          <div class="inside-wrapper">
            <div class="element-animated">
              <svg width="600" height="600" viewBox="0 0 100 100">
                <!-- Draw the lines first -->
                <line
                  class="triangle"
                  :x1="triangleVertices[0].x"
                  :y1="triangleVertices[0].y"
                  :x2="triangleVertices[1].x"
                  :y2="triangleVertices[1].y"
                />
                <line
                  class="triangle"
                  :x1="triangleVertices[1].x"
                  :y1="triangleVertices[1].y"
                  :x2="triangleVertices[2].x"
                  :y2="triangleVertices[2].y"
                />
                <line
                  class="triangle"
                  :x1="triangleVertices[2].x"
                  :y1="triangleVertices[2].y"
                  :x2="triangleVertices[0].x"
                  :y2="triangleVertices[0].y"
                />

                <!-- Overlay a white-filled circle beneath the black stroked circle to cover the parts of the lines outside -->
                <!-- This circle is slightly larger than the black-stroked one and positioned under it -->
                <circle
                  cx="50"
                  cy="50"
                  r="47"
                  fill="transparent"
                  stroke="white"
                  stroke-width="22"
                />

                <!-- Draw the black stroked circle -->
                <circle
                  cx="50"
                  cy="50"
                  r="36"
                  fill="transparent"
                  stroke="black"
                  stroke-width="1"
                />
              </svg>
            </div>
            <div class="text-container">
              <h1>Limited Run<br /><span>Coffees</span><br />from Orlando</h1>
            </div>
          </div>
        </div>
        <div class="one-title">Our Current Offerings ↓</div>
        <div class="coffee-bag-display">
          <div
            v-for="(coffee, index) in coffees"
            :key="index"
            class="coffee-item"
          >
            <div class="coffee-pic" @click="openUrl">
              <img :src="coffee.imageUrl" :alt="coffee.name" />
            </div>
            <div class="coffee-info" @click="openUrl">
              <h2>{{ coffee.name }}</h2>
              <p>{{ coffee.description }}</p>
            </div>
          </div>
        </div>
        <div class="button-wrapper">
          <button @click="openUrl" class="get-in-touch">Visit Our Store</button>
        </div>
      </div>
    </div>
    <section v-else class="mobile-retail-section">
      <div class="mobile-title-container">
        <h2 class="sub-motto title">
          <span>The</span><span class="oneoff-span"> One Off</span>
        </h2>
        <h2 class="sub-motto title"><span>Coffee's Profiles</span></h2>

        <p class="sub-motto">
          Discover the various profiles that make Aizhak's coffee unique and
          explore the tastes and aromas that each type of roast we offer.
        </p>
        <div class="mobile-wheel-wrapper">
          <FlavorWheel @flavor-selected="handleFlavorSelected" />
        </div>

        <div v-if="currentSelectedFlavor" class="flavor-details">
          <p>{{ currentSelectedFlavor.description }}</p>
          <h3 :style="{ color: currentSelectedFlavor.color }">
            {{ currentSelectedFlavor.name }}
          </h3>
        </div>
      </div>
      <div class="inside-wrapper">
        <div class="element-animated">
          <svg width="600" height="600" viewBox="0 0 100 100">
            <!-- Draw the lines first -->
            <circle
              cx="50"
              cy="50"
              r="36"
              fill="#faf6f2"
              stroke="transparent"
              stroke-width="1"
            />
            <line
              class="triangle"
              :x1="triangleVertices[0].x"
              :y1="triangleVertices[0].y"
              :x2="triangleVertices[1].x"
              :y2="triangleVertices[1].y"
            />
            <line
              class="triangle"
              :x1="triangleVertices[1].x"
              :y1="triangleVertices[1].y"
              :x2="triangleVertices[2].x"
              :y2="triangleVertices[2].y"
            />
            <line
              class="triangle"
              :x1="triangleVertices[2].x"
              :y1="triangleVertices[2].y"
              :x2="triangleVertices[0].x"
              :y2="triangleVertices[0].y"
            />
          </svg>
        </div>
        <div class="text-container mobile">
          <h1>Limited Run<br /><span>Coffees</span><br />from Orlando</h1>
        </div>
      </div>

      <div class="mobile-retail-info"></div>
      <div class="one-title-mobile">Our Current Offerings ↓</div>
      <div class="mobile-coffee-list">
        <div
          v-for="(coffee, index) in coffees"
          :key="index"
          class="mobile-coffee-item"
        >
          <img
            :src="coffee.imageUrl"
            :alt="coffee.name"
            class="mobile-coffee-image"
            @click="openUrl"
          />
          <div class="mobile-coffee-info" @click="openUrl">
            <h3>{{ coffee.name }}</h3>
            <p>{{ coffee.description }}</p>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button @click="openUrl" class="get-in-touch mobile-button">
          Visit Our Store
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  computed,
  ref,
} from "vue";
import FlavorWheel from "../components/FlavorWheelComponent.vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { db } from "@/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  name: "RetailComponent",
  components: {
    FlavorWheel,
  },
  setup() {
    const radius = ref(32); // Circle's radius

    // Computed property to calculate the vertices of the triangle
    const triangleVertices = computed(() => {
      const height = radius.value * Math.sqrt(3);
      const sideLength = radius.value * 2;
      const halfBase = sideLength / 2;

      return [
        { x: 50, y: 50 - (2 * height) / 3 }, // Top vertex
        { x: 50 - halfBase, y: 50 + height / 3 }, // Bottom left vertex
        { x: 50 + halfBase, y: 50 + height / 3 }, // Bottom right vertex
      ];
    });

    const windowWidth = ref(window.innerWidth);
    const isDesktop = computed(() => windowWidth.value > 768);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const initScrollAnimations = () => {
      const div2 = document.querySelector(".div2");
      const div3 = document.querySelector(".div3");

      if (div2) {
        gsap.to(".div2", {
          x: () => `-${div2.offsetWidth - window.innerWidth}px`,
          ease: "none",
          scrollTrigger: {
            trigger: ".div2",
            start: "top top",
            end: () => `+=${div2.offsetWidth}`,
            scrub: true,
            pin: true,
            anticipatePin: 1,
          },
        });
      }

      if (div3) {
        gsap.from(".div3", {
          scrollTrigger: {
            trigger: ".div3",
            start: "top bottom",
            end: "bottom bottom",
            scrub: true,
          },
          opacity: 0,
          ease: "none",
        });
      }
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      initScrollAnimations();
      gsap.from(".text-container h1, .text-container span, .element-animated", {
        scrollTrigger: {
          trigger: ".text-container",
          start: "top center", // Animation starts when the top of text-container hits the center of the viewport
          end: "bottom center", // Optional: You can define an end point for the trigger
          toggleActions: "play none none none", // Defines what happens when the scrollTrigger is hit. In this case, play the animation and do nothing on leave.
        },
        duration: 1,
        opacity: 0,
        y: 70,
        stagger: 0.2,

        filter: "blur(10px)",
        ease: "power2.out",
        onComplete: () =>
          gsap.to(
            ".text-container h1, .text-container span, .element-animated",
            {
              filter: "blur(0px)",
              duration: 1,
            }
          ),
      });

      gsap.from(".title-container .sub-motto, .title-container h2", {
        scrollTrigger: {
          trigger: ".title-container",
          start: "top center", // Adjust as needed
          end: "bottom center", // Optional
          toggleActions: "play none none none",
        },
        duration: 1,
        opacity: 0,
        y: 70,
        stagger: 0.2,
        delay: 0.2,
        filter: "blur(10px)",
        ease: "power2.out",
        onComplete: () =>
          gsap.to(".title-container .sub-motto, .title-container h2", {
            filter: "blur(0px)",
            duration: 0.5,
          }),
      });

      gsap.to(".triangle", {
        rotation: 360,
        transformOrigin: "50% 50%",
        duration: 7,
        repeat: -1,
        ease: "none",
        svgOrigin: "50 50",
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return { isDesktop, handleResize, triangleVertices };
  },
  data() {
    return {
      currentSelectedFlavor: null,
      // coffees: [
      //   {
      //     imageUrl: require("@/assets/amazonas.png"),
      //     name: "Amazonas, Peru",
      //     description: "Amazonas, Peru",
      //     class: "left-coffee",
      //   },
      //   {
      //     imageUrl: require("@/assets/bourbon.png"),
      //     name: "Bourbon Blend, Amazonas, Peru",
      //     description: "Bourbon Blend, Amazonas, Peru",
      //     class: "center-coffee",
      //   },
      //   {
      //     imageUrl: require("@/assets/aji.png"),
      //     name: "Aji Chili Co-Fermented, Colombia",
      //     description: "Aji Chili Co-Fermented, Colombia",
      //     class: "right-coffee",
      //   },
      // ],
      coffees: [],
    };
  },
  async mounted() {
    this.fetchCoffees();
    this.addClickEventsToCoffeeElements();
  },

  methods: {
    addClickEventsToCoffeeElements() {
      this.$nextTick(() => {
        const coffeeElements = document.querySelectorAll(
          ".coffee-item, .mobile-coffee-item"
        );
        coffeeElements.forEach((el) => {
          el.addEventListener("click", () => {
            window.open(
              "https://one-off-coffee-roasters-llc.square.site",
              "_blank"
            );
          });
        });
      });
    },
    async fetchCoffees() {
      const querySnapshot = await getDocs(collection(db, "coffees"));
      this.coffees = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    handleFlavorSelected(flavor) {
      this.currentSelectedFlavor = flavor;
    },
    openUrl() {
      window.open("https://one-off-coffee-roasters-llc.square.site");
    },
    animateTransitionEffect() {
      const tl = gsap.timeline({ repeat: -1, yoyo: true });
      tl.to(".transition-effect", {
        duration: 1,
        background: "linear-gradient(to bottom, #ffffff, #000)",
        ease: "sine.inOut",
      }).to(".transition-effect", {
        duration: 1.5,
        background: "linear-gradient(to bottom, #000, #ffffff)",
        ease: "sine.inOut",
      });
    },
  },
});
</script>

<style scoped>
.text-container {
  display: flex;
  line-height: 1;
  font-size: 3.25rem;

  padding-right: 4rem;
  font-family: "Leiko Regular";
}

.text-container span {
  font-size: 6rem;
}

.inside-wrapper {
  display: flex;
  align-items: center;
}
.maximum-wrapper {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.content-wrapper {
  text-align: center;
  margin-bottom: 2rem;
}

.flavor-details {
  background-color: rgba(20, 20, 20, 0.85);
  color: #eeddc7;
  padding: 1rem;
  border-radius: 8px;
  max-height: 100px;
  max-width: 300px;
  transition: opacity 0.3s ease; /* Smooth transition for details */
}

.element-animated svg {
  max-width: 100%;
  height: auto;
}
.flavor-details h3 {
  display: flex;
  justify-content: flex-end;
  font-size: 1.25rem;
  /* font-family: "Playfair Display", serif; */
  font-weight: 700;
  letter-spacing: 1px;
}

.flavor-details p {
  display: flex;
  max-width: 200px;
  font-size: 1rem;
  padding-left: 0.5rem;
}
.div2 {
  display: flex;
  height: 100vh;
  width: 300vw;
  position: relative;
}
.div2 div {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.coffee-p {
  padding-top: 0.5rem;
  font-size: 1.25rem;
  justify-content: start;
  font-weight: 600;
}

.div22 {
  background-image: url("~@/assets/slide-pics/50ppi/Break1.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.coffee-desc {
  font-size: 1rem;
  color: rgb(82, 82, 82);
}
.div23 {
  background-color: #ececec;
  background-image: url("~@/assets/slide-pics/50ppi/Asset2.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.div2mobile {
  background-image: url("~@/assets/slide-pics/50ppi/Asset2.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: none;
}

.trust-wrapper {
  display: flex;
  align-items: end;
  padding-bottom: 4rem;
  padding-left: 4rem;
  font-size: 12rem;
  font-family: "Leiko Regular";
  color: #ececec;
  justify-content: center;
}

.div24 {
  background-color: #ffffff;
  background-image: url("~@/assets/slide-pics/50ppi/Break2.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.shop-wrapper {
  background-color: #ffffff;
  display: flex;
  text-align: left;
  justify-content: space-evenly;
  font-family: "Spartan MB";
}

.info {
  display: flex;
  margin: auto 0;
  background-color: #ffffff;
  color: rgb(180, 180, 180);
  padding-top: 4rem;
  padding-left: 3rem;
}

.coffee-pic img {
  width: 420px; /* Fixed width */
  height: 380px; /* Fixed height */
  object-fit: cover; /* This will cover the area without stretching the image */
  /* Define the clipping path */
  cursor: pointer;
}

.div3 {
  background-color: #fff;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 300px;
  padding-bottom: 100px;
}

.div3 .container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.title-container {
  margin: 0;
  padding: 0;
  /* background-color: rgb(43, 0, 0); */

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.info-container {
  display: grid;

  margin: 1rem auto;
  max-width: 1200px;
  margin-top: 300px;

  grid-template-columns: auto 1fr;
}

.sub-motto {
  color: #faf6f2;
  font-size: 1.25rem;
  margin: 0 auto; /* Center the element */
  margin-bottom: 2rem;
  max-width: 700px; /* Maximum width set to 600px */
  font-family: "Space Grotesk Light";
}

.sub-motto span {
  font-size: 4rem;
}

.one-title {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 3rem;
  font-family: "Leiko Regular";
}

.title {
  font-family: "Leiko Regular"; /* Stylish serif font */
  font-size: 6rem;
  line-height: 0.7;
}

.button-wrapper {
  margin: 0 auto;
  text-align: center;
}

.bt-line {
  margin-top: 2rem;
  margin-right: 1rem;
}

.button-wrapper h2 {
  color: #faf6f2;
  font-size: 2rem;
  font-family: "Leiko Regular";
}

.retail-container {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: small;
  margin: 300px auto;
  margin-top: 100px;
  max-width: 1200px;
}
.get-in-touch {
  color: #333;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  border: solid #333 1px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  margin-bottom: 1rem;
  font-family: "Space Grotesk";
}

.get-in-touch:hover {
  background-color: #333;
  color: #eeddc7;
}

.retail-section {
  display: flex;
  flex-direction: row;
  max-width: 40%;
}

.wheel-wrapper {
  margin: 0;
  padding: 0;
  /* background-color: rgb(88, 19, 19); */
  flex-direction: row;
  display: flex;

  justify-content: center;
  justify-items: center;
  align-content: center;
}

h2 {
  font-weight: 500;
  margin: 0 0 12px 0; /* Adjust margins as needed, remove margin-left */
  color: #da9950;
  font-family: "Space Grotesk";
}

.header-section {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.triangle {
  stroke: #090909;
  stroke-width: 2;
  fill: none;
}

.coffee-bag-display {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.coffee-item {
  flex: 0 1 300px; /* Flex basis of 220px, but allow wrapping */
  text-align: center;
}

.coffee-info {
  margin-top: 1rem;
  cursor: pointer;
}

.coffee-info h2 {
  font-size: 1.5rem;
  color: #333;
}

.coffee-info p {
  color: #666;
  font-size: 1rem;
}

.button-wrapper {
  text-align: center;
  margin-top: 4rem;
}
@media (max-width: 1080px) {
  .title {
    line-height: 1;
  }
}

@media (max-width: 768px) {
  .button-wrapper {
 
  margin-top: 1rem;
}
  .mobile-button {
    display: flex;

    align-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .one-title-mobile {
    color: #f8f8f8;
    display: flex;
    justify-content: center;
    font-family: "Space Grotesk";
    margin-top: 2rem;

    font-size: 1.25rem;
  }
  .inside-wrapper {
    margin-inline: 2rem;
    margin-top: 6rem;
    margin-bottom: 1rem;
  }
  .text-container {
    line-height: 1;
  }

  .text-container h1 {
    font-size: 2.5rem;
    color: #faf6f2;
    font-family: "Leiko Regular";
  }

  .text-container span {
    font-size: 3.25rem;
  }
  .triangle {
    stroke: #090909;
    stroke-width: 2;
    fill: none;
  }
  .flavor-wheel {
    max-width: 240px;
  }

  .sub-motto h2 {
    font-size: 2rem;
  }

  .sub-motto {
    font-size: 2.25rem;
  }
  .div2 {
    width: 200vw;
  }

  .div22,
  .div24 {
    display: none;
  }

  .div23 {
    display: block;
  }

  .div2mobile {
    display: block;
  }

  .sub-motto span {
    font-size: 2rem; /* Adjust for smaller screens */
  }

  .button-wrapper h2 {
    font-size: 1.4rem; /* Adjust for smaller screens */
  }

  .get-in-touch {
    padding: 0.7rem 1.2rem; /* Adjust for smaller screens */
    font-size: 1.2rem; /* Adjust for smaller screens */
    border: solid 1px #faf6f2;
    color: #faf6f2;
  }
  .mobile-wheel-wrapper,
  .flavor-details {
    display: flex;
    justify-content: center; /* This will center the children horizontally */
    align-items: center; /* This will center the children vertically */
    text-align: center;
    margin: 0 auto; /* Center the container itself if needed */
    width: 100%; /* Take full width */
  }

  .flavor-details p,
  .flavor-details h3 {
    /* If you have specific styles for p and h3 that prevent centering, override them here */
    width: auto; /* Let them take only as much width as needed */
    margin: 0 auto; /* Center the p and h3 within flavor-details */
  }
}

@media (max-width: 480px) {
  .motto {
    font-size: 2rem; /* Further reduce for very small screens */
  }

  .sub-motto {
    font-size: 1rem; /* Further reduce for very small screens */
  }

  .button-wrapper h2 {
    font-size: 1.2rem; /* Further reduce for very small screens */
  }

 
}

.mobile-layout {
  padding: 15px;
  color: #333;
  background-color: #f8f8f8;
}

.mobile-header-section h2 {
  font-size: 24px;
  text-align: center;
  color: #444;
  margin-bottom: 20px;
}

.mobile-retail-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.mobile-wheel-wrapper {
  padding: 1rem;
  grid-column: 1 / -1; /* Full width */
  text-align: center;
}

.mobile-title-container {
  padding: 1rem;
  grid-column: 1 / -1; /* Full width */
  text-align: center;
}

.mobile-flavor-details {
  background-color: rgba(20, 20, 20, 0.85);
  color: #eeddc7;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
}

@media (max-width: 480px) {
  .mobile-header-section h2 {
    font-size: 20px;
  }

  .mobile-flavor-details {
    font-size: 14px;
  }
}

.circle {
  fill: white;
  stroke: black;
  stroke-width: 1;
}

.mobile-coffee-list {
 
  display: grid;
  grid-template-columns: 1fr; /* Single column layout */
  gap: 20px;
  padding-inline: 3rem;
}

.mobile-coffee-item {
  
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mobile-coffee-image {
  width: 100%;
  height: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
}

.mobile-coffee-info {
  padding: 15px;
  text-align: center;
  font-family: "Space Grotesk";
}

.mobile-coffee-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.mobile-coffee-info p {
  font-size: 14px;
  color: #666;
}

/* Adjustments for very small screens */
@media (max-width: 480px) {
  .mobile-coffee-info h3 {
    font-size: 16px;
  }

  .mobile-coffee-info p {
    font-size: 12px;
  }
}
</style>
