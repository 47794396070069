<template>
  <div class="wrapper">
    
    <div class="login-container">
      
      <h2>Admin Login</h2>
      
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <div class="button-wrapper">
          <button type="submit">Login</button>
        </div>
      </form>
      <div class="back-home-link">
        <router-link to="/">Back to main site</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

export default {
  name: "LoginComponent",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.$router.push({ name: "Admin" });
        })
        .catch((error) => {
          console.error("Error signing in:", error);
          alert("Login failed: " + error.message);
        });
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: antiquewhite;
  font-family: "Space Grotesk";
  background-color: rgb(12, 12, 12);
}

h2 {
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: #da9950;
  font-family: "Spartan MB";
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-size: small;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(34, 34, 34);
}

button[type="submit"] {
  padding: 0.75rem;
  background: none;
  transition: all 0.3s ease;
  border: solid #da9950 1px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  margin: auto;
}

.button-wrapper {
  display: flex;
  margin-top: 1rem;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

button[type="submit"]:hover {
  background-color: #daa76e;
}

.back-home-link {
  margin-top: 1rem;
  text-align: center;
}

.back-home-link a {
  color: #aaaaaa; /* Match the color scheme of the login page */
  text-decoration: none;
  
  transition: color 0.3s ease;

  
}
</style>
