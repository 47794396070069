<template>
  <div class="wrapper">
    <!-- Coffee Store Items Section -->
    <div class="admin-section">
      <h1 class="section-title">Coffee Store Items</h1>
      <div class="coffee-form">
        <input type="text" v-model="coffee.name" placeholder="Coffee Name" />
        <textarea
          v-model="coffee.description"
          placeholder="Coffee Description"
        ></textarea>
        <input type="file" @change="handleFileUpload" />
        <input type="file" ref="fileInput" style="display: none" />

        <button @click="addOrUpdateCoffee">Save Coffee</button>
      </div>
      <div class="coffee-list">
        <div v-for="coffee in coffees" :key="coffee.id">
          <img :src="coffee.imageUrl" :alt="coffee.name" />
          <h3>{{ coffee.name }}</h3>
          <p>{{ coffee.description }}</p>
          <button @click="editCoffee(coffee)">Edit</button>
          <button @click="deleteCoffee(coffee.id)">Delete</button>
        </div>
      </div>
    </div>

    <!-- Newsletter Creation Tool Section -->
    <div class="admin-section">
      <h1 class="section-title">Newsletter</h1>

      <!-- Keep the Quill editor as a div -->
      <div
        ref="newsletterEditorContainer"
        class="editor"
        style="display: none"
      ></div>
      <div class="buttons">
        <button
          :disabled="isSending"
          @click="sendNewsletter"
          class="send-button"
        >
          POST Newsletter
        </button>
        <button @click="logout" class="logout-button">Logout</button>
      </div>
    </div>

    <!-- Subscriber Info Section -->
    <div class="admin-section subsWrapper">
      <h1 class="section-title">Subscriber Info</h1>
      <transition name="sand-transition">
        <div class="subscriber-info" v-if="subscribersIsVisible">
          <div class="subscriber-count">Subscribers: {{ subscriberCount }}</div>
          <div class="subscriber-emails">
            <ul>
              <li v-for="(email, index) in subscribers" :key="index">
                {{ email }}
              </li>
            </ul>
          </div>
        </div>
      </transition>
      <div class="toggle-subs" @click="toggleSubs">Toggle Subscribers Info</div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import Quill from "quill";
import ImageResize from "quill-image-resize";
import "quill/dist/quill.snow.css";
import { auth, db, storage } from "@/firebaseConfig";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

// Register the Quill image resize module
Quill.register("modules/imageResize", ImageResize);

function inlineStyles(htmlContent) {
  // Create a new div element
  const container = document.createElement("div");
  container.innerHTML = htmlContent;

  // Get all elements with inline styles
  const elementsWithStyles = container.querySelectorAll("[style]");

  // Loop through elements and copy inline styles
  elementsWithStyles.forEach((element) => {
    const computedStyle = window.getComputedStyle(element);
    const style = Array.from(computedStyle)
      .map((prop) => `${prop}:${computedStyle.getPropertyValue(prop)}`)
      .join("; ");
    element.setAttribute("style", style);
  });

  return container.innerHTML;
}

export default {
  name: "AdminComponent",
  setup() {
    const subscriberCount = ref(0);
    const subscribers = ref([]);

    const fetchSubscribers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "subscribers"));
        subscriberCount.value = querySnapshot.size;
        subscribers.value = querySnapshot.docs.map((doc) => doc.data().email);
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      }
    };

    onMounted(async () => {
      await fetchSubscribers();
    });

    return {
      subscriberCount,
      subscribers,
    };
  },
  data() {
    return {
      quill: null,
      isSending: false,
      subscribersIsVisible: true,
      newsletterSubject: "",
      coffee: { name: "", description: "", imageUrl: "" },
      coffees: [],
      selectedFile: null,
      imagesForNewsletter: [],
    };
  },
  async mounted() {
    await this.fetchCoffees();
    this.initQuillEditor(this.$refs.newsletterEditorContainer);
  },
  methods: {
    initQuillEditor(editorContainer) {
      this.quill = new Quill(editorContainer, {
        theme: "snow",
        modules: {
          // imageResize: {},
          toolbar:
            // [
            //   [{ header: [1, 2, false] }],
            //   ["bold", "italic", "underline", "strike"],
            //   ["link", "code-block"],
            //   [{ list: "ordered" }, { list: "bullet" }],
            //   [{ script: "sub" }, { script: "super" }],
            //   [{ indent: "-1" }, { indent: "+1" }],
            //   [{ direction: "rtl" }],
            //   [{ size: ["small", false, "large", "huge"] }],
            //   [{ color: [] }, { background: [] }],
            //   [{ font: [] }],
            //   [{ align: [] }],
            //   ["image", "clean"],
            // ],
            false,
        },
        placeholder: "Compose an epic...",
        readOnly: false,
      });

      // this.quill.getModule("toolbar").addHandler("image", () => {
      //   this.selectLocalImage();
      // });
    },

    selectLocalImage() {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.click();
      input.onchange = async () => {
        const file = input.files[0];
        if (file) {
          const imageUrl = await this.uploadImageToFirebase(file);
          this.insertToEditor(imageUrl);
        }
      };
    },

    insertToEditor(url) {
      const range = this.quill.getSelection(true);
      if (range && range.index !== null) {
        // Instead of setting the style directly, you would get the actual size from the Quill editor
        // For demonstration purposes, we set a static width and height.
        // You should get these values dynamically from the Quill ImageResize module or the image itself after resizing.
        const resizedWidth = "500px"; // Placeholder for the dynamic width obtained from Quill
        const resizedHeight = "auto"; // Height is set to auto to maintain the aspect ratio

        const imageHtml = `<div style="text-align: center; display: block;">
                            <img src="${url}" style="width: ${resizedWidth}; height: ${resizedHeight}; max-width: 100%; display: block; margin-left: auto; margin-right: auto;">
                         </div>`;
        this.quill.clipboard.dangerouslyPasteHTML(range.index, imageHtml);
      }
    },

    async uploadImageToFirebase(file) {
      try {
        const imageRef = storageRef(
          storage,
          `newsletterImages/${Date.now()}-${file.name}`
        );
        const uploadResult = await uploadBytes(imageRef, file);
        return await getDownloadURL(uploadResult.ref);
      } catch (error) {
        console.error("Error uploading image to Firebase: ", error);
        throw error;
      }
    },
    async fetchCoffees() {
      const querySnapshot = await getDocs(collection(db, "coffees"));
      this.coffees = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },

    async uploadImage(file) {
      const imageRef = storageRef(storage, `images/${Date.now()}-${file.name}`);
      const uploadResult = await uploadBytes(imageRef, file);
      return getDownloadURL(uploadResult.ref);
    },
    async addOrUpdateCoffee() {
      let imageUrl = this.coffee.imageUrl; // Retain the existing URL if no new file is selected

      // If there's a selected file, upload it and get the URL
      if (this.selectedFile) {
        try {
          imageUrl = await this.uploadImage(this.selectedFile);
        } catch (error) {
          console.error("Error during file upload: ", error);
          // Handle the error appropriately
          alert("Failed to upload image. Please try again.");
          return; // Exit the method if the upload fails
        }
      }

      // Now proceed to either update or add the coffee
      if (this.coffee.id) {
        // Update existing coffee
        await updateDoc(doc(db, "coffees", this.coffee.id), {
          ...this.coffee,
          imageUrl: imageUrl, // Use the newly uploaded image URL
        });
      } else {
        // Add new coffee
        await addDoc(collection(db, "coffees"), {
          ...this.coffee,
          imageUrl: imageUrl, // Use the newly uploaded image URL
        });
      }

      // Reset form after the operation
      this.resetCoffeeForm();
    },
    resetCoffeeForm() {
      this.coffee = { name: "", description: "", imageUrl: "" };
      this.selectedFile = null;
      this.fetchCoffees();
    },
    editCoffee(coffee) {
      this.coffee = { ...coffee };
    },
    async deleteCoffee(coffeeId) {
      await deleteDoc(doc(db, "coffees", coffeeId));
      await this.fetchCoffees();
    },
    toggleSubs() {
      this.subscribersIsVisible = !this.subscribersIsVisible;
    },
    async sendNewsletter() {
      const emailHtml = this.quill.root.innerHTML; // Get the HTML content from Quill editor

      // Function to apply inline styles to elements with class 'ql-align-center'
      const applyInlineStyles = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");
        const centerElements = doc.querySelectorAll(".ql-align-center");

        centerElements.forEach((element) => {
          element.style.textAlign = "center";
          element.classList.remove("ql-align-center"); // Remove Quill-specific class
        });

        // Serialize the modified HTML content back into a string
        const serializer = new XMLSerializer();
        return serializer.serializeToString(doc);
      };

      const emailHtmlContentWithStyles = applyInlineStyles(emailHtml);
      const rawHtmlContent = emailHtmlContentWithStyles;

      console.log("Passing: ", rawHtmlContent);

      if (!rawHtmlContent.trim()) {
        alert("Please write some content for the newsletter.");
        return;
      }

      // Generate attachments with a predictable content_id
      const attachments = this.imagesForNewsletter.map((image, index) => ({
        content: image.base64,
        filename: `image${index}.png`, // Give each image a unique filename
        type: image.mimetype,
        disposition: "inline",
        content_id: `image${index}`, // The content_id needs to be predictable
      }));

      // Use DOMParser to modify the HTML content
      const parser = new DOMParser();
      const doc = parser.parseFromString(rawHtmlContent, "text/html");
      const images = doc.querySelectorAll("img");
      images.forEach((img, index) => {
        if (img.src && img.src.startsWith("data:")) {
          img.src = `cid:image${index}`; // Set the src to the corresponding content_id
        }
        const resizedWidth = "300px";
        img.setAttribute(
          "style",
          `display: block; width: ${resizedWidth}; max-width: 100%; height: auto;`
        );
      });

      // Serialize the modified HTML content back into a string
      const serializer = new XMLSerializer();
      const emailHtmlContent = serializer.serializeToString(doc);

      this.isSending = true;
      try {
        const token = await auth.currentUser.getIdToken();
        const personalizations = this.subscribers.map((email) => ({
          to: [{ email }],
          subject: this.newsletterSubject,
          dynamic_template_data: {
            content: emailHtmlContent, // Use the modified HTML content
          },
        }));

        const payload = {
          personalizations,
          template_id: "d-f6e54a94579f432fa382564587c65a80",
          attachments,
        };

        const response = await fetch(
          "https://us-central1-aizhak-website.cloudfunctions.net/sendNewsletter",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Network response was not ok: ${errorText}`);
        }

        // Reset the Quill editor content
        this.quill.setContents([{ insert: "\n" }]);

        alert("Newsletter sent successfully!");
      } catch (error) {
        console.error("Error sending newsletter:", error);
        alert(`Error sending newsletter: ${error.message}`);
      } finally {
        this.isSending = false;
      }
      this.destroyAndReinitializeQuill("newsletterEditorContainer");
    },
    destroyAndReinitializeQuill(editorRef) {
      if (this.quill) {
        this.quill.off("text-change");
        // First, ensure that the editor toolbar is removed
        if (this.quill.getModule("toolbar")) {
          this.quill.getModule("toolbar").container.remove();
        }
        // Now, we can safely nullify the Quill instance
        this.quill = null;
      }

      // Clear the innerHTML of the editor container to remove any remaining Quill UI
      if (this.$refs[editorRef]) {
        this.$refs[editorRef].innerHTML = "";
      }

      this.$nextTick(() => {
        this.initQuillEditor(this.$refs[editorRef]);
      });
    },

    logout() {
      auth
        .signOut()
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
}

.admin-section {
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  text-align: center;
}

.coffee-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.coffee-form input[type="text"],
.coffee-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.coffee-form input[type="text"]:focus,
.coffee-form textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.coffee-form input[type="file"] {
  margin-bottom: 15px;
}

.coffee-form button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.coffee-form button:hover {
  background-color: #45a049;
}

.coffee-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.coffee-list div {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.coffee-list img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.coffee-list h3 {
  margin: 10px 0;
  font-size: 18px;
  color: #333;
}

.coffee-list p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.coffee-list button {
  background-color: #008cba;
  color: white;
  border: none;
  padding: 7px 10px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.coffee-list button:hover {
  background-color: #007b9e;
}

.newsletter-subject input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.newsletter-subject input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.editor {
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.send-button,
.logout-button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:hover,
.logout-button:hover {
  background-color: #0056b3;
}

.subsWrapper {
  width: 100%;
}

.subscriber-info {
  background-color: #424242;
  padding: 1rem;
  border-radius: 4px;
  color: #f1f1f1;
  margin-top: 20px;
}

.subscriber-count {
  margin-bottom: 10px;
}

.subscriber-emails {
  text-align: left;
}

.toggle-subs {
  cursor: pointer;
  color: #007bff;
  margin-top: 20px;
}

.toggle-subs:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .admin-section {
    margin-bottom: 20px;
  }

  .editor {
    height: 200px;
  }
}
</style>
