<template>
  <section>
    <div class="info-container" ref="infoSectionRef" id="infoId">
      <div class="header-section">
        <h2>About</h2>
      </div>

      <div class="info-section">
        <div class="text-content">
          <h1 class="info-title">A Commitment to Coffee Excellence</h1>
          <div class="mobile-image-container" v-if="isMobile">
            <img
              :src="mobileImageSrc"
              alt="Mobile Profile"
              class="mobile-info-image"
            />
            <img
              :src="hoverImageSrc"
              alt="Hover Profile"
              class="mobile-info-image"
              v-show="hovering"
            />
          </div>
          <div class="info-description">
            <p>
              Our journey has been marked by an unwavering passion for quality,
              and a profound understanding of the importance of relationships
              within coffee. With our collective experience, we can bring a
              wealth of knowledge to any project, from quick service carts to
              full manufacturing facilities.
            </p>
            <br />
            <p>
              Our work extends beyond consulting. We believe in empowering
              coffee shop owners and teams with the skills and insights
              necessary to increase efficiency, lower costs, and provide a
              world-class experience for your clients, all at the same time.
            </p>
            <br />
            <p>
              Our services are comprehensive, covering everything from barista
              and roaster training, equipment procurement, supply chain
              management, and big-picture business development. We pride
              ourselves on providing bespoke solutions that fit the unique needs
              of each client, ensuring that each establishment we work with can
              deliver the best possible coffee experience.
            </p>
          </div>
        </div>
        <div
          class="image-container"
          @mouseenter="hoverImage"
          @mouseleave="unhoverImage"
        >
          <div class="image-wrapper">
            <img :src="image1" alt="Profile" class="info-image" />
            <img :src="image2" alt="Profile" class="info-image hover-image" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="image-container"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
      @touchstart="hovering = true"
      @touchend="hovering = false"
    >
      <div class="image-wrapper">
        <img :src="image3" alt="Profile" class="info-image base-image" />
        <img
          :src="image4"
          alt="Profile"
          class="info-image hover-image"
          :style="{ opacity: hovering ? 1 : 0 }"
        />
      </div>
    </div>
    <div class="mission-statement-section">
      <div class="mission-statement-content">
        <p>
          <span class="quotemark">"</span>
          Empowering our community with
          <span class="highlight">exceptional</span> coffee
          <span class="highlight">expertise</span>.

          <span class="quotemark">"</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";

import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { debounce } from "lodash-es"; // Make sure to install lodash-es if you haven't

export default defineComponent({
  name: "InfoSection",
  mounted() {
    this.initScrollAnimations();
    this.initMissionStatementAnimations();
  },
  setup() {
    const image1 = require("../assets/az-bio.jpg");
    const image2 = require("../assets/about2.png");
    const isMobile = ref(window.innerWidth < 768);
    const desktopImageSrc = require("../assets/az-bio.jpg");
    const mobileImageSrc = require("../assets/about2.png");
    const hovering = ref(false);

    // Debounce the resize function to prevent it from firing too many times in quick succession
    const onResize = debounce(() => {
      isMobile.value = window.innerWidth < 768;
    }, 500);

    onMounted(() => {
      window.addEventListener("resize", onResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });

    return {
      image1,
      image2,
      isMobile,
      desktopImageSrc,
      mobileImageSrc,

      hovering,
    };
  },
  data() {
    return {
      image3: "/assets/az-bio.jpg",
      image4: "/assets/about2.png",
    };
  },
  methods: {
    initMissionStatementAnimations() {
      this.$nextTick(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".mission-statement-section",
            start: "top bottom -=500",
            toggleActions: "play none none none",
          },
        });

        tl.from(".mission-statement-section .quotemark", {
          duration: 1,
          opacity: 0,
          x: -30,
          stagger: 0.2,
          ease: "power2.out",
        }).from(
          ".mission-statement-section p",
          {
            duration: 1.5,
            opacity: 0,

            y: 100,
            ease: "power4.out",
            filter: "blur(7px)",
            onComplete: () =>
              gsap.to(".mission-statement-section", {
                filter: "blur(0px)",
                duration: 0.7,
              }),
          },
          "-=0.5"
        );
      });
    },
    initScrollAnimations() {
      this.$nextTick(() => {
        gsap.from(".info-title", {
          scrollTrigger: {
            trigger: ".info-title",
            start: "top bottom",
            toggleActions: "play none none reverse",
          },
          delay: 0.2,
          opacity: 0,
          y: 50,
          duration: 1,
          ease: "power2.out",
          filter: "blur(10px)",
          onComplete: () =>
            gsap.to(".info-title", { filter: "blur(0px)", duration: 0.5 }),
        });

        gsap.from(".info-description", {
          scrollTrigger: {
            delay: 0.2,
            trigger: ".info-description",
            start: "top bottom",
            toggleActions: "play none none reverse",
          },
          opacity: 0,
          y: 50,
          duration: 1,
          delay: 0.1,
          ease: "power2.out",
          filter: "blur(10px)",
          onComplete: () =>
            gsap.to(".info-description", {
              filter: "blur(0px)",
              duration: 0.5,
            }),
        });
      });
    },
  },
});
</script>

<style scoped>
.image-wrapper {
  position: relative;
}

.info-container {
  display: grid;

  margin: 1rem auto;
  max-width: 1200px;
  margin-top: 300px;
  margin-bottom: 300px;
  grid-template-columns: auto 1fr;
}

.info-section {
  display: flex;
  flex-direction: row;

  gap: 2rem;
  width: 100%;
}

.highlight {
  color: #faf6f2;
}

h2 {
  color: #da9950;
  font-size: small;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-content {
  max-width: 500px;
  margin-right: 2rem;
}

.info-title {
  font-size: 4rem;
  color: #faf6f2;
  font-family: "Leiko Regular";
  margin-bottom: 2rem;
  line-height: 1.2;
}

.info-description {
  font-size: small;
  color: #faf6f2;
  font-family: "Space Grotesk Light";
  line-height: 1.6;
  max-width: 450px;
}

.image-container {
  max-width: 500px;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.info-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: clip-path 0.7s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.hover-image {
  clip-path: inset(0 0 100% 0);
}

.image-container:hover .hover-image {
  clip-path: inset(0 0 0 0);
}

.header-section {
  display: block;
}

.mission-statement-section {
  /* background-color: #aaaaaa; */
  color: #da9950;
  padding: 2rem;

  margin-top: 3rem;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

.is-hovering {
  clip-path: inset(0 0 0 0);
}

.mission-statement-header h2 {
  font-size: 4rem;
  color: #1f1e1c;
  margin-bottom: 1rem;
  font-family: "Leiko Regular";
}

.mission-statement-content::before {
  opacity: 0.3;
  content: "STATEMENT";
  /* Styles for the 'STATEMENT' background text */
  font-size: 10rem;
  /* Large font size for the background text */
  color: rgba(175, 175, 175, 0.1);
  /* Light color for the background text and some transparency */
  position: absolute;
  /* Position absolutely to place it behind the content */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Offset the element by half of its width and height */
  z-index: 0;
  /* Place it behind the content text */

  /* Additional styles to match the design */
  font-family: "Leiko Regular";
  /* Use a font that matches your design */
  font-weight: bold;
  /* Make it bold */
  white-space: nowrap;
  /* Prevent it from wrapping */
  overflow: hidden;
  /* Hide any overflow */
  pointer-events: none;
  /* Ensure mouse events are not captured by the background text */
}

.mission-statement-content {
  position: relative;
  /* Needed for absolute positioning of the pseudo-element */
  text-align: center;
  /* Center the text */
  padding: 10rem 0;
  /* Increase top and bottom padding to provide space for the background text */
  overflow: hidden;
  /* Ensures that no part of the pseudo-element will be shown outside this container */
  font-size: 1.25rem;
  font-family: "Space Grotesk";
}

@media (max-width: 1080px) {
  .info-container {
    max-width: 95%;
    /* Slightly reduce the width for medium screens */
  }

  .info-title {
    font-size: 3rem;
    /* Adjust title size for medium screens */
  }

  .info-description {
    font-size: 0.9rem;
    /* Adjust description text size */
  }

  .mission-statement-header h2 {
    font-size: 3rem;
    /* Adjust mission statement title size */
  }
}

@media (max-width: 768px) {
  .mobile-image-container {
    display: block;
    /* Show on mobile */
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .mobile-info-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .desktop-image-container {
    display: none;
    /* Hide desktop image container on mobile */
  }

  .info-container {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 1.5rem;
  }

  .header-section {
    display: none;
  }

  .info-section {
    flex-direction: column;
    gap: 1rem;
  }

  .text-content,
  .image-container {
    max-width: 100%;
    margin: 0;
  }

  .info-title {
    font-size: 2.25rem;
  }

  .info-description {
    font-size: 0.85rem;
    text-align: justify;
  }

  .mission-statement-content::before {
    font-size: 3rem;
    opacity: 0.7;
    top: 50%;
    left: 50%;
  }
}

@media (max-width: 480px) {
  .info-title {
    font-size: 2.25rem;
  }

  .info-description {
    font-size: 0.8rem;
  }
}
</style>
