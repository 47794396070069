// store/index.js
import { createStore } from 'vuex';

// Create a new store instance.
const store = createStore({
  state: {
    isContactPopupOpen: false,
  },
  mutations: {
    TOGGLE_CONTACT_POPUP(state) {
      state.isContactPopupOpen = !state.isContactPopupOpen;
    },
  },
  actions: {
    toggleContactPopup({ commit }) {
      commit('TOGGLE_CONTACT_POPUP');
    },
  },
});

export default store;
