<template>
  <div class="main-container">
    <div v-if="showMessage" class="submission-message">
      {{ submissionMessage }}
    </div>
    <div class="contact-container">
      <!-- Video Container for Larger Screens -->

      <!-- Contact Section -->
      <section class="contact-section">
        <h2>Contact Me</h2>
        <h2 class="motto">
          Craft Exceptional Coffee Experiences for your Business
        </h2>
        <p class="sub-motto">
          Your journey towards coffee perfection is just a conversation away.
          Reach out with your ideas, challenges, or aspirations, and let's craft
          a partnership that transforms them into reality.
        </p>
      
          <button @click="togglePopup" class="get-in-touch">Get in Touch</button>
     
       

        <!-- Popup Form Overlay -->
        <div v-if="isPopupOpen" class="form-overlay" @click="togglePopup"></div>

        <!-- Popup Form -->
        <transition name="fade">
          <div v-if="isPopupOpen" class="contact-form-container">
            <form @submit.prevent="submitForm" class="contact-form">
              <button type="button" @click="togglePopup" class="close-button">
                X
              </button>
              <h2>Thanks for your interest!</h2>
              <p>Please fill the information below to get started</p>
              <div class="form-group">
                <input type="text" v-model="name" placeholder="Name" required />
                <input
                  type="email"
                  v-model="email"
                  placeholder="Email"
                  required
                />
                <input
                  type="tel"
                  v-model="mobile"
                  placeholder="Mobile"
                  required
                />
                <textarea
                  v-model="details"
                  placeholder="Details that you'd like to share"
                  required
                ></textarea>
              </div>

              <div class="sub-wrapper">
                <input
                  type="checkbox"
                  id="newsletterCheckbox"
                  name="newsletterCheckbox"
                  v-model="subNewsletter"
                />

                <label for="newsletterCheckbox"
                  >Also subscribe to our Newsletter</label
                >
              </div>

              <button type="submit" class="send-button">Submit</button>
            </form>
          </div>
        </transition>
      </section>

      <div class="video-container">
        <video class="contact-video" autoplay muted loop playsinline>
          <source src="../assets/aizhakworking.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
// import { subscribeToNewsletter } from "../disposables/useNewsletterSubscription"; // Update the path
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { db } from "@/firebaseConfig"; // Make sure this path is correct
import { collection, addDoc } from "firebase/firestore";

export default defineComponent({
  name: "ContactComponent",
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const isPopupOpen = computed(() => store.state.isContactPopupOpen);

    // Refs for form inputs
    const subNewsletter = ref(false);
    const name = ref("");
    const email = ref("");
    const mobile = ref("");
    const details = ref("");
    const showMessage = ref(false);
    const submissionMessage = ref("");

    function togglePopup() {
      store.dispatch("toggleContactPopup");
    }

    const subscribeToNewsletter = async (emailAddress) => {
      try {
        await addDoc(collection(db, "subscribers"), { email: emailAddress });
        console.log("Subscription successful");
        return "Thank you for subscribing!";
      } catch (error) {
        console.error("Error subscribing to newsletter:", error);
        return "Failed to subscribe. Please try again later.";
      }
    };

    async function submitForm() {
      // Form submission logic
      const formData = {
        name: name.value,
        email: email.value,
        mobile: mobile.value,
        details: details.value,
      };

      try {
        await addDoc(collection(db, "inquiries"), formData);
        console.log("Inquiry submitted");

        // Check if the newsletter subscription checkbox is checked
        if (subNewsletter.value) {
          console.log("Checkbox is checked, attempting to subscribe");
          const newsletterResponse = await subscribeToNewsletter(email.value);
          console.log(newsletterResponse);
        }

        // Reset form and show submission message
        showMessage.value = true;
        submissionMessage.value =
          "Thank you for your message! We will get back to you soon.";
        name.value = "";
        email.value = "";
        mobile.value = "";
        details.value = "";
        subNewsletter.value = false; // Reset the checkbox

        // Close popup and reset message after a delay
        togglePopup();
        setTimeout(() => {
          showMessage.value = false;
          submissionMessage.value = "";
        }, 2000);
      } catch (error) {
        console.error("Error sending data", error);
      }
    }

    return {
      subscribeToNewsletter,
      subNewsletter,
      isPopupOpen,
      togglePopup,
      submitForm,
      name,
      email,
      mobile,
      details,
      showMessage,
      submissionMessage,
    };
  },
});
</script>

<style scoped>
.sub-wrapper {
  display: flex;
  align-items: center;
  color: #faf6f2;
  margin-left: 4px;
  margin-bottom: 1rem;
}

.sub-wrapper input[type="checkbox"] {
  margin-right: 10px;
  padding: 0;
  width: auto;
}
.main-container {
  background-color: #ffffff;
}
.submission-message {
  position: fixed; /* Or absolute, depending on your layout */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's above other elements */
  background-color: #ffffff; /* Or any other color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  /* Add more styling as per your design */
}
.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
}

.video-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  overflow: hidden; /* Prevents video overflow */
}

.contact-video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover; /* Cover the space without losing aspect ratio */
  opacity: 1; /* Video transparency */
}

.contact-section {
  position: relative; /* Position relative for z-index to work */
  z-index: 1;
  flex: 1;
  padding: 60px 10%;
}
.motto {
  color: #090909;
  font-size: 4rem;
  max-width: 800px;
  text-align: left;
  font-family: "Leiko Regular";
  margin-bottom: 1rem;
  line-height: 1.2;
}

.sub-motto {
  color: #090909;
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: left;
  max-width: 600px;
  font-size: 1.25rem;
  font-family: "Space Grotesk Light";
}

.contact-container {
  display: grid;
  /* padding: 60px 10%;  */
  margin: 300px auto;
  grid-template-columns: auto 1fr;
  font-size: small;
  max-width: 1200px;
  margin-bottom: 150px;
}
.get-in-touch {
  background-color: #c39352;
  color: #050505;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  border: solid #c39352 1px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Space Grotesk";

  margin-bottom: 1rem;
}

.get-in-touch:hover {
  background-color: #050505;
  color: #eeddc7;
 
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.contact-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  background-color: #201e1c;
  padding: 2rem;

  border-radius: 2px;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
  float: right;
  border: none;
  background: none;
  color: #eeddc7;
  font-size: 1.5rem;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.contact-form .form-group {
  margin-bottom: 1rem;
}

.contact-form label {
  display: block;
  margin-bottom: 0.5rem;
}

.contact-form h2 {
  color: #d4af37;
  font-size: 1.2rem;
  margin-bottom: 0;
  font-family: "Spartan MB";
}
.contact-form p {
  color: #faf6f2;
  margin-bottom: 1rem;
  font-family: "Space Grotesk";
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: #050505;
  color: #faf6f2;
  font-size: small;
}

.send-button {
  background-color: #c39352;
  color: #050505;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  border: solid #c39352 1px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Space Grotesk";
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.send-button:hover {
  background-color: #050505;
  color: #faf6f2;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

h2 {
  margin: 0 0 24px 0; /* Adjust margins as needed, remove margin-left */
  color: #da9950;
}

@media (max-width: 768px) {
  .main-container {
    background-color: #090909;
  }
  .motto {
    font-size: 2.5rem; /* Adjust for smaller screens */
    color: #ececec;
  }

  h2 {
    margin-inline: 0.5rem;
    font-size: 0.75rem;
  }

  .sub-motto {
    font-size: 1rem; /* Adjust for smaller screens */
    color: #ececec;
  }

  .get-in-touch {
    padding: 0.7rem 1.2rem; /* Adjust for smaller screens */
    font-size: 1.2rem; /* Adjust for smaller screens */
  }

  .contact-form {
    padding: 1rem;
  }

  .contact-container {
    position: relative;
    width: 100%;
    overflow: hidden; /* This prevents horizontal scrolling */
  }

  .video-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); /* This will center the video-container */
    width: 100vw; /* This will make the video container the width of the viewport */
    height: 100%; /* Set to 100% of the parent container's height */
  }

  .contact-video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the video */
    opacity: 0.25; /* Set desired opacity */
  }
}

@media (max-width: 480px) {
  .motto {
    font-size: 2rem; /* Further reduce for very small screens */
  }



 

  .contact-form {
    width: 90%;
  }
}

.mobile-only-image {
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio */
  display: none; /* Hide by default */
  mix-blend-mode: darken;
  background-color: #090909;
}

/* Show image only on mobile devices */
</style>
