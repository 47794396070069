import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, EmailAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Import getStorage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA7ibm2jbpnna5tKupn8P1aqFDn0Jy4_V8",
  authDomain: "aizhak-website.firebaseapp.com",
  projectId: "aizhak-website",
  storageBucket: "aizhak-website.appspot.com",
  messagingSenderId: "625530796020",
  appId: "1:625530796020:web:4f904afa30027ff3ae2ba1",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { db, auth, EmailAuthProvider, storage };
