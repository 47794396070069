<template>
  <div class="flavor-wheel">
    <svg
      :class="['circular-chart', { hovering: hovering }]"
      viewBox="0 0 100 100"
    >
      <path
        v-for="(flavor, index) in flavors"
        :key="`flavor-${index}`"
        :d="calculateOuterPath(index, flavors.length)"
        :fill="flavor.color"
        @mouseover="handleMouseOver(flavor)"
        @mouseout="handleMouseOut"
        @click="handleFlavorClick($event, flavor)"
        class="flavor-path"
      />

      <path
        v-for="(aroma, index) in aromas"
        :key="`aroma-${index}`"
        :d="calculateInnerPath(index, aromas.length)"
        :fill="aroma.color"
        @mouseover="handleMouseOver(aroma)"
        @mouseout="handleMouseOut"
        @click="handleFlavorClick($event, aroma)"
        class="aroma-path"
      />
      <path
        v-for="(item, index) in thirdRing"
        :key="`thirdRing-${index}`"
        :d="calculateThirdPath(index, thirdRing.length)"
        :fill="item.color"
        @mouseover="handleMouseOver(item)"
        @mouseout="handleMouseOut"
        @click="handleFlavorClick($event, item)"
        class="third-ring-path"
      />
    </svg>
    <!-- <div v-if="selectedFlavor" class="flavor-details">
      <p>{{ selectedFlavor.description }}</p>
      <h3 :style="{ color: selectedFlavor.color }">
        {{ selectedFlavor.name }}
      </h3>
    </div> -->
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "FlavorWheel",
  data() {
    return {
      showPopup: false,
      iframeLoaded: false,
      shopUrl: "https://one-off-coffee-roasters-llc.square.site",
      flavors: [
        {
          name: "Spicy",
          description: "Clove and anise.",
          color: "#C29252",
        },

        // {
        //   name: "Bitter",
        //   description: "Notes of cinnamon and nutmeg.",
        //   color: "#A9A9A9",
        // },
        {
          name: "Winey",
          description: "Complex layers akin to a fine aged red wine.",
          color: "#B47435",
        },

        {
          name: "Sugar Browning",
          description: "Vibrant fruit-like qualities.",
          color: "#C8A586",
        },
        {
          name: "Jammy",
          description: " Jam like qualities.",
          color: "#EE880F",
        },

        {
          name: "Juicy",
          description: "Evoking Cherry and pear flavors.",
          color: "#C29252",
        },
        {
          name: "Sweet",
          description: "Mellow aromatic dessert-like aromas.",
          color: "#4D3A20",
        },
      ],
      aromas: [
        {
          name: "Aromas",
          description: "Warm notes of cinnamon and clove.",
          color: "#F6E6D6",
        },
        {
          name: "Mouthfeels",
          description: "Sweetness of caramel and chocolate.",
          color: "#A9A9A9",
        },
      ],
      thirdRing: [
        // {
        //   name: "Earthy",
        //   description: "Reminiscent of soil and natural tones.",
        //   color: "#EE880F",
        // },
        // {
        //   name: "Herbal",
        //   description: "Fresh herbal notes like basil and sage.",
        //   color: "#B47435",
        // },
        {
          name: "Floral",
          description: "Refreshing Lavender, jasmine, rose.",
          color: "#D99850",
        },
        {
          name: "Nutty",
          description: "Toasted hazelnuts with a dash of almond sweetness.",
          color: "#A9A9A9",
        },
        {
          name: "Fruity",
          description: "Juicy berries and ripe stone fruits.",
          color: "#C29252",
        },
        {
          name: "Citrus",
          description: "Vibrant notes of grapefruit and tangerine.",
          color: "#4D3A20",
        },
        {
          name: "Effervescent",
          description: "Sweet Juicy & Sangria Mouthfeel.",
          color: "#B47435",
        },
        {
          name: "Caramelized",
          description: "Rich toffee and buttery brittle.",
          color: "#F6E6D6",
        },
        // {
        //   name: "Winey",
        //   description: "Complex layers akin to a fine aged red wine.",
        //   color: "#C8A586",
        // },
      ],
      selectedFlavor: null,
      hovering: false,
      resumeSpinTimeout: null,
      spinningInterval: null,
    };
  },
  mounted() {
    this.startSpinning();
  },
  computed: {
    chartClass() {
      return this.hovering ? "circular-chart hovering" : "circular-chart";
    },
  },
  methods: {
    handleFlavorClick(event, flavor) {
      // GSAP animation for the clicked segment
      gsap.fromTo(event.target, 
        { scale: 1.5, transformOrigin: '60% 60%' },
        { scale: 1, duration: 0.5, ease: 'elastic.out(1, 0.3)' });

      // Additional click handling code...
    },
    startSpinning() {
      // Clear existing interval before starting a new one
      clearInterval(this.spinningInterval);
      this.spinningInterval = setInterval(() => {
        if (!this.hovering) {
          this.selectedFlavor = this.getRandomFlavor();
          this.$emit("flavor-selected", this.getRandomFlavor());
        }
      }, 3000); // Change the flavor every 4 seconds
    },
    getRandomFlavor() {
      const allFlavors = [...this.flavors, ...this.aromas, ...this.thirdRing];
      return allFlavors[Math.floor(Math.random() * allFlavors.length)];
    },
    handleMouseOver(item) {
      clearInterval(this.spinningInterval);
      this.selectedFlavor = item;
      this.hovering = true;
      this.$emit("flavor-selected", this.selectedFlavor); // Emit the selected flavor
    },
    handleMouseOut() {
      // Set the hover state to false and start spinning after a delay
      this.hovering = false;
      clearTimeout(this.resumeSpinTimeout); // Clear any existing timeout
      this.resumeSpinTimeout = setTimeout(() => {
        this.startSpinning();
      }, 1500);
    },
    calculateInnerPath(index, total) {
      const radius = 20;
      const anglePerSegment = (2 * Math.PI) / total;
      const startAngle = anglePerSegment * index - Math.PI / 2;
      const endAngle = startAngle + anglePerSegment;

      const startX = 50 + radius * Math.cos(startAngle);
      const startY = 50 + radius * Math.sin(startAngle);
      const endX = 50 + radius * Math.cos(endAngle);
      const endY = 50 + radius * Math.sin(endAngle);

      return `M50,50 L${startX},${startY} A${radius},${radius} 0 0,1 ${endX},${endY} Z`;
    },
    calculateOuterPath(index, total) {
      // Logic for middle ring path calculation
      const innerRadius = 20;
      const outerRadius = 35;
      const anglePerSegment = (2 * Math.PI) / total;
      const startAngle = anglePerSegment * index - Math.PI / 2;
      const endAngle = startAngle + anglePerSegment;

      const startXInner = 50 + innerRadius * Math.cos(startAngle);
      const startYInner = 50 + innerRadius * Math.sin(startAngle);
      const endXInner = 50 + innerRadius * Math.cos(endAngle);
      const endYInner = 50 + innerRadius * Math.sin(endAngle);

      const startXOuter = 50 + outerRadius * Math.cos(startAngle);
      const startYOuter = 50 + outerRadius * Math.sin(startAngle);
      const endXOuter = 50 + outerRadius * Math.cos(endAngle);
      const endYOuter = 50 + outerRadius * Math.sin(endAngle);

      return (
        `M${startXInner},${startYInner}` +
        `A${innerRadius},${innerRadius} 0 0,1 ${endXInner},${endYInner}` +
        `L${endXOuter},${endYOuter}` +
        `A${outerRadius},${outerRadius} 0 0,0 ${startXOuter},${startYOuter}Z`
      );
    },
    calculateThirdPath(index, total) {
      // Logic for third outer ring path calculation
      const innerRadius = 35;
      const outerRadius = 50;
      const anglePerSegment = (2 * Math.PI) / total;
      const startAngle = anglePerSegment * index - Math.PI / 2;
      const endAngle = startAngle + anglePerSegment;

      const startXInner = 50 + innerRadius * Math.cos(startAngle);
      const startYInner = 50 + innerRadius * Math.sin(startAngle);
      const endXInner = 50 + innerRadius * Math.cos(endAngle);
      const endYInner = 50 + innerRadius * Math.sin(endAngle);

      const startXOuter = 50 + outerRadius * Math.cos(startAngle);
      const startYOuter = 50 + outerRadius * Math.sin(startAngle);
      const endXOuter = 50 + outerRadius * Math.cos(endAngle);
      const endYOuter = 50 + outerRadius * Math.sin(endAngle);

      return (
        `M${startXInner},${startYInner}` +
        `A${innerRadius},${innerRadius} 0 0,1 ${endXInner},${endYInner}` +
        `L${endXOuter},${endYOuter}` +
        `A${outerRadius},${outerRadius} 0 0,0 ${startXOuter},${startYOuter}Z`
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.spinningInterval);
    clearTimeout(this.resumeSpinTimeout);
  },
};
</script>

<style scoped>
.flavor-wheel-header {
  margin-bottom: 6rem;
}

.flavor-wheel-header h1 {
  font-family: "Playfair Display", serif;
  color: #faf6f2;
  margin-bottom: 2rem;
  font-size: 4rem; /* Starting font-size, will adjust with media queries */
}

.flavor-wheel-header p {
  color: #faf6f2;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  font-size: small;
}

.flavor-wheel {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
  max-width: 500px;
}

.circular-chart {
  width: 100%;
  height: auto;
}

.flavor-path,
.aroma-path,
.third-ring-path {
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.flavor-path:hover,
.aroma-path:hover,
.third-ring-path:hover {
  opacity: 0.7;
  fill: white;
}

.flavor-details {
  position: absolute;
  top: 0%;
  right: 0%; /* Align to the right of the container */
  transform: translateX(
    200%
  ); /* Center vertically relative to its own height */
  background-color: rgba(20, 20, 20, 0.85);
  color: #eeddc7;
  padding: 1rem;

  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  width: 80%;
  max-width: 300px;
  transition: opacity 0.3s ease; /* Smooth transition for details */
  overflow: hidden;
}

.flavor-details h3 {
  display: flex;
  justify-content: flex-end;
  font-size: 1.25rem;
  /* font-family: "Playfair Display", serif; */
  font-weight: 700;
  letter-spacing: 1px;
}

.flavor-details p {
  display: flex;
  max-width: 200px;
  font-size: 1rem;
  padding-left: 0.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circular-chart {
  width: 100%;
  height: auto;
  animation: spin 20s linear infinite; /* Adjust time for desired spin speed */
}

.hovering {
  animation-play-state: paused; /* Pause the animation when hovering */
}

/* Responsive Typography */
@media (max-width: 768px) {
  .flavor-wheel-header h1 {
    font-size: 2rem;
  }

  .flavor-wheel-header p {
    font-size: 0.9rem;
  }

  .flavor-details {
    transform: translateY(0);
    font-size: 0.8rem;
    right: 5%;
    top: 50%;
  }
}

@media (max-width: 480px) {
  .flavor-wheel-header h1 {
    font-size: 1.5rem;
  }

  .flavor-details {
    top: 40%;
    padding: 0.5rem;
    font-size: 0.7rem;
  }
}
</style>
