<template>
  <div class="navbar-container" :class="{ 'navbar-hidden': !navbarVisible }">
    <nav class="navbar">
      <ul class="nav-links l">
        <li class="nav-item">
          <a href="javascript:void(0);" @click="scrollToElement('infoId')"
            >About</a
          >
        </li>
        <li class="nav-item">
          <a href="javascript:void(0);" @click="scrollToElement('servicesId')"
            >Services</a
          >
        </li>
        <li class="nav-item">
          <a href="javascript:void(0);" @click="scrollToElement('coffeeId')"
            >Flavors</a
          >
        </li>
      </ul>
      <div class="separator"></div>
      <div class="logo-wrapper">
        <img
          src="../assets/50ppi/Asset1.png"
          alt="Aizhak's Logo"
          class="logo"
          loading="lazy"
        />
      </div>
      <div class="separator"></div>
      <ul class="nav-links contact-links">
        <li
          class="nav-item buy-coffee"
          onclick="window.open('https://one-off-coffee-roasters-llc.square.site', '_blank');"
        >
          <a href="/">Shop Coffee</a>
        </li>
        <li class="nav-item contact" @click.prevent="openContactPopup">
          <a href="/contact">Contact Me</a>
        </li>
      </ul>
    </nav>
  </div>
  <div v-if="sidebarOpen" class="overlay" @click="toggleSidebar"></div>
  <nav
    class="navbar-mobile"
    :class="{
      'navbar-hidden': !navbarVisible,
      'with-sidebar-open': sidebarOpen,
    }"
    v-if="isMobile"
  >
    <img
      src="../assets/50ppi/50ppi/logo-left.png"
      alt="Logo"
      class="logo-mobile"
      @click="scrollToElement('start-id')"
    />
    <div class="hamburger" @click="toggleSidebar">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>
  </nav>
  <div class="sidebar" v-bind:class="{ open: sidebarOpen }">
    <ul class="nav-links mob">
      <li class="nav-item mob" @click="scrollToElement('infoId')">About</li>
      <li class="nav-item mob" @click="scrollToElement('servicesId')">
        Services
      </li>
      <li class="nav-item mob" @click="scrollToElement('coffeeId')">
        Flavors
      </li>
      <li
        class="nav-item mob"
        onclick="window.open('https://one-off-coffee-roasters-llc.square.site', '_blank');"
      >
        Shop Coffee
      </li>
      <li class="nav-item mob" @click.prevent="openContactPopup">Contact Me</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      isMobile: false,
      sidebarOpen: false,
      lastScrollY: 0,
      navbarVisible: true,
    };
  },
  created() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkScreenSize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToElement(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.closeSidebar();
    },
    openContactPopup() {
      // Assuming your store setup is correct
      this.$store.dispatch("toggleContactPopup");
      if (this.sidebarOpen) {
        this.sidebarOpen = false;
      }
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768;
    },

    handleScroll() {
      const currentScrollY = window.scrollY;
      if (currentScrollY < this.lastScrollY) {
        this.navbarVisible = true;
      } else if (currentScrollY > this.lastScrollY && currentScrollY > 100) { 
        this.navbarVisible = false;
      }
      this.lastScrollY = currentScrollY;
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    closeSidebar() {
      this.sidebarOpen = false;
    },
    handleNavClick() {
      this.scrollToElement("infoId"); // Update to your requirement
      this.closeSidebar();
    },
  },
};
</script>

<style scoped>
.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(1px);
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.navbar-hidden .navbar-container {
  top: -100%;
}


.navbar {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent; /* Transparent background */
}

.nav-links,
.contact-links {
  list-style: none;
  display: flex;
  padding: 0;
}

.nav-item {
  padding: 1rem;
  color: #faf6f2;
  text-decoration: none;
  font-size: 14px;
  font-family: "Space Grotesk Light";
}

.logo-wrapper {
  display: none;
}

.buy-coffee {
  background-color: #4d3a20 !important;
  border: solid #faf6f2 1px;
  border-radius: 6px;
  padding: 0.75rem;
  color: rgb(238, 238, 238);
  font-weight: 500;
  cursor: pointer;
}

.contact {
  padding: 0.75rem;
  background: none;
  transition: all 0.3s ease;
  border: solid #da9950 1px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  margin-inline: 0.5rem;
}

.contact:hover {
  background-color: #da9950;
  color: rgb(20, 20, 20);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.navbar-mobile {
  padding-top: 1rem;
  padding-inline: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  z-index: 101;
  backdrop-filter: blur(3px); /* Apply blur by default */
  -webkit-backdrop-filter: blur(3px);
}
.navbar-mobile.with-sidebar-open {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

.navbar-hidden {
  transform: translateY(-100%); /* Hide the navbar by moving it up */
}

.logo-mobile {
  max-width: 80px;
  height: auto;
  flex-shrink: 0;
}

.hamburger {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  z-index: 101;
}

.bar {
  display: block;
  height: 3px;
  width: 30px;
  background-color: #faf6f2;
  transition: transform 0.3s ease;
}

.hamburger .bar:first-child.active {
  transform: translateY(8px) rotate(45deg);
}

.hamburger .bar:nth-child(2).active {
  opacity: 0;
}

.hamburger .bar:last-child.active {
  transform: translateY(-8px) rotate(-45deg);
}

.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 130px;
  background-color: #aaaaaa;
  transition: right 0.3s ease;
  z-index: 100;
  display: flex; /* Set the display to flex to align items in a row */
  flex-direction: row; /* Align flex items horizontally */
  flex-wrap: wrap; /* Allow items to wrap as needed */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.sidebar.open {
  right: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  backdrop-filter: blur(5px); /* Blur effect for the background */
  z-index: 50; /* Ensure it's below the sidebar but above other content */
}

.mob {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  color: #090909;
}

.nav-item.mob {
  color: #090909;
  font-weight: bold;
}

.nav-item {
  padding: 1rem;

  text-decoration: none;
}

.nav-links .l {
  color: #faf6f2;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .navbar-mobile {
    display: flex;
  }

  .hamburger {
    display: flex;
  }
}

@media (max-width: 480px) {
  .logo-mobile {
    max-width: 140px;
  }
}
</style>
