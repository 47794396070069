<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="left-section">
        <img
          src="../assets/logo-w.png"
          alt="Logo"
          class="footer-logo"
          
        />
        <p>&copy; 2024 Aizhak Nensey. All rights reserved.</p>
      </div>
      <div class="right-section">
        <div class="social-media">
          <a
            href="https://www.instagram.com/oneoffcoffee?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
          >
            <img
              src="../assets/social-icons/instagram_4902354.png"
              alt="Instagram"
              class="icon"
            />
          </a>
          <a href="mailto:info@aizhak.com">
            <img
              src="../assets/social-icons/email_6244710.png"
              alt="Gmail"
              class="icon"
            />
          </a>
          <a
            href="https://one-off-coffee-roasters-llc.square.site"
            target="_blank"
          >
            <img
              src="../assets/social-icons/square-logo.png"
              alt="Square"
              class="icon"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer {
  background-color: #090909;
  color: #ffffff;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
}

.footer-content {
  margin-inline: 2rem;
  border-top: 2px solid #1f1f1f;
  padding-top: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligns to the left */
  justify-content: center;
  margin-inline: 2rem;
}

.footer-logo {
  max-width: 100px; /* Adjust the logo size as needed */
  margin-bottom: 1rem;
  cursor: pointer;
}

.right-section {
  width: 300px;
  height: 200px; /* Adjust height as needed to fit the background image */
  display: flex;
  align-items: flex-end; /* Align the social icons to the bottom */
  justify-content: center;
  background-image: url("../assets/cuppingpic.png");
  background-size: cover; /* Use 'cover' to make sure the image covers the area */
  background-position: center center; /* Center the image in the div */
  position: relative;
  margin-inline: 2rem;
}

.social-media {
  position: absolute;
  bottom: 10px; /* Positioned at the bottom */
  display: flex;
  flex-direction: row; /* Stack icons vertically */
  align-items: center; /* Center icons horizontally */
  padding: 0; /* Remove padding if previously set */
}

.social-media img {
  width: 2rem; /* Size of social media icons */
  margin-bottom: 0.5rem; /* Adjust space between icons */
  margin-inline: 1.5rem;
}

.social-media a {
  display: inline-block; /* Make the anchor inline-block to allow margin and padding */
  margin: 0.5rem;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.social-media a:hover {
  transform: translateY(-5px); /* Move the icon up on hover */
}

.icon {
  width: 2rem; /* Size of social media icons */
}

@media (max-width: 768px) {
  .footer-content {
    margin: 0;
    flex-direction: column-reverse;
  }

  .left-section {
    text-align: center;
  }

  .left-section,
  .right-section {
    align-items: center;
    padding: 2rem;
  }

  .right-section {
    height: 100px;
  }
}

@media (max-width: 480px) {
  .footer-content {
    padding: 0 1rem;
    padding-top: 1rem;
    border-top: none;
  }
}
</style>
