<template>
  <div class="expertise-container" id="servicesId">
    <div class="header-section">
      <h2 class="services-title">Services</h2>
    </div>
    <div class="main-content">
      <h1 class="section-title">Anything Coffee. Here for you.</h1>
      <p>
        Whether you're looking to enhance the coffee experience you offer,
        streamline your operations, or explore new horizons in the world of
        coffee, we are here to guide you. With a comprehensive understanding of
        the coffee industry and a versatile skill set, we provide personalized
        consultancy tailored to your unique vision and goals. Below are some of
        the typical services that we offer:
      </p>
      <ul class="services-container">
        <li
          v-for="service in services"
          :key="service"
          class="service-item"
          :style="{ '--service-opacity': service.opacity }"
          @mousemove="(e) => handleMov(e)"
          @mouseenter="(e) => handleEnter(e)"
          @mouseleave="(e) => handleLeave(e)"
        >
          <div
            class="service-image"
            :style="{
              backgroundImage: `url(${service.imageUrl})`,
            }"
          />

          <picture class="service-index">
            <img :src="service.svgIcon" alt="svgIcon" />
          </picture>

          <div class="service-content">
            <h3 class="service-title" :style="{ opacity: service.opacity }">
              {{ service.title }} -
            </h3>
            <p
              class="service-description"
              :style="{ opacity: service.opacity }"
            >
              {{ service.description }}
            </p>
          </div>
        </li>
        <!-- <div
          class="service-row"
          v-for="(service, index) in services"
          :key="index"
          :style="{ '--service-opacity': service.opacity }"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @mousemove="(e) => handleEnter(e)"
        >
          <div
            class="service-image"
            :style="{ backgroundImage: `url(${service.imageUrl})` }"
          ></div>
          <div class="service-index" :style="{ opacity: service.opacity }">
            <img :src="service.svgIcon" alt="Service Icon" />
          </div>

          <div class="service-content">
            <h3 class="service-title" :style="{ opacity: service.opacity }">
              {{ service.title }} -
            </h3>
            <p
              class="service-description"
              :style="{ opacity: service.opacity }"
            >
              {{ service.description }}
            </p>
          </div>
        </div> -->
      </ul>
    </div>
  </div>
</template>
<!-- New div for the separator line -->
<!-- <div
        class="service-separator"
        :style="{ opacity: service.opacity }"
        v-if="index !== services.length - 1"
      ></div> -->
<script>
import ServiceIcon1 from "@/assets/services-icons/dataicon.svg";
import ServiceIcon2 from "@/assets/services-icons/eqicon.svg";
import ServiceIcon3 from "@/assets/services-icons/sourcicon.svg";
import ServiceIcon4 from "@/assets/services-icons/packicon.svg";
import ServiceIcon5 from "@/assets/services-icons/qcicon.svg";
import ServiceIcon6 from "@/assets/services-icons/dataicon.svg";

import ServImg from "@/assets/foh-training.jpg";
import EqupDesignImg from "@/assets/bar-concept-with-two-barmen.jpg";
import CoffeeSrcImg from "@/assets/coffee-sourcing.png";
import { gsap } from "gsap";

export default {
  name: "ServicesSection",
  mounted() {
    gsap.utils.toArray(".service-content").forEach((row) => {
      gsap.from(row, {
        opacity: 0,
        y: 70, // adjust this value for the sliding effect
        duration: 0.7, // duration of the animation
        filter: "blur(7px)",

        scrollTrigger: {
          trigger: row,
          start: "top 80%",
          onComplete: () =>
            gsap.to(".service-index", {
              filter: "blur(0px)",
              duration: 0.5,
            }),
          toggleActions: "play none none none",
        },
      });
    });
  },
  data() {
    return {
      rotation: 0,
      isMouseOver: false,
      services: [
        {
          title: "FOH Training",
          description:
            "Developing a positive culture is the most important aspect of creating a positive customer experience. We can help you establish guidelines and pathways to preserve and maintain this culture, all while teaching coffee skills and inspiring a hospitality-focused experience behind the bar. ",
          imageUrl: ServImg,
          opacity: 1,
          svgIcon: ServiceIcon1,
        },
        {
          title: "Equipment & Design",
          description:
            "Your equipment is more than machinery. It’s the brandable centerpiece of your operation. It’s your reliable partner-in-crime, every day. Don’t get stuck having to spend twice; let us help you source quality equipment that will last for years.",
          imageUrl: EqupDesignImg,
          opacity: 1,
          svgIcon: ServiceIcon2,
        },
        {
          title: "Coffee Sourcing",
          description:
            "Relationships are everything in coffee. Finding the right supplier for your coffee is an essential part of your business - let us introduce you to our network of world class coffee professionals. ",
          imageUrl: CoffeeSrcImg,
          opacity: 1,
          svgIcon: ServiceIcon3,
        },
        {
          title: "Packaging",
          description:
            "Your packaging is your sales pitch. Communicate your brand, share your vision, and don’t break the bank - let us develop a packaging concept for you, and introduce you to the premier packaging suppliers in North America.",
          imageUrl: "path_to_your_image",
          opacity: 0.8,
          svgIcon: ServiceIcon4,
        },
        {
          title: "Quality Control",
          description:
            "Doing something well once is difficult. Doing something well every time is even tougher. We can help you set standards, benchmarks, and testing processes that save you from negative reviews and inconsistency. ",
          imageUrl: "path_to_your_image",
          opacity: 0.6,
          svgIcon: ServiceIcon5,
        },
        {
          title: "Data Management",
          description:
            "Collecting information is the single easiest way to find product-market fit. Understanding what makes your product yours, why customers love your coffee, and where you can continue to grow is essential to building a sustainable business. Let us help you understand what to look for, how to store it, and how to build a data-based pathway to success.",
          imageUrl: "path_to_your_image",
          opacity: 0.55,
          svgIcon: ServiceIcon6,
        },
      ],
    };
  },
  methods: {
    handleEnter(event) {
      const imageEl = event.currentTarget.querySelector(".service-image");
      imageEl.style.opacity = 1;
    },

    handleLeave(event) {
      const imageEl = event.currentTarget.querySelector(".service-image");

      imageEl.style.opacity = 0;
    },

    handleMov(event) {
      const container = event.currentTarget;

      const imageEl = event.currentTarget.querySelector(".service-image");

      const containerRect = container.getBoundingClientRect();

      const relativeX = event.clientX - containerRect.left;
      const relativeY = event.clientY - containerRect.top;
      let posX = relativeX - imageEl.offsetWidth / 2 + "px";
      let posY = relativeY - imageEl.offsetHeight / 2 + "px";

      let centerX = window.innerWidth / 2;
      let offsetX = event.clientX - centerX; // Distance from center
      let maxRotation = 12; // Maximum rotation angle in degrees

      // Calculate rotation based on offset and max rotation
      this.rotation = (offsetX / centerX) * maxRotation;
      imageEl.style.transform = ` translate(${posX}, ${posY}) 
      rotateZ(${Math.floor(this.rotation)}deg)`;
    },
  },
};
</script>

<style scoped>
.service-index img {
  width: 90px; /* Same as the previous font size */
  height: auto; /* Maintain aspect ratio */
}
.service-row {
  opacity: 0;
  /* transform: translateY(50px); */
}

/* controls for image hover */
.service-image {
  position: absolute;
  width: 300px; /* Adjust size as needed */
  height: 300px; /* Adjust size as needed */
  background-size: cover;
  background-position: center;
  pointer-events: none; /* So it doesn't interfere with mouse events */
  opacity: 0; /* Initially hidden */
  transition: all 1000ms cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 100;
}

.expertise-container {
  display: grid;
  grid-template-columns: auto 1fr;
  color: #faf6f2;
  margin: 300px auto;
  max-width: 1200px;
}

.header-section {
  display: flex;
}

.main-content p {
  margin-bottom: 1.5rem;
}

h2 {
  color: #da9950;
  font-size: small;
  padding-left: 1rem;
  padding-right: 1rem;
}

.main-content {
  display: grid;
  gap: 1rem;
}

.service-details {
  display: flex;
}

.section-title {
  font-family: "Leiko Regular";
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 0;
}

.services-container {
  /* display: grid; */
  /* grid-template-columns: [index-start] 10% [index-end title-start] auto [title-end description-start] 1fr [description-end]; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 2rem;
  /* overflow: hidden; */
}

.service-item {
  display: flex;
  position: relative;
  cursor: default;
  /* background: rgb(199, 146, 146); */
  /* grid-template-columns: 1fr auto; */
}

.service-row {
  display: flex;
  align-items: center;
}

.service-content {
  position: relative;
  padding-top: 2rem;
  flex: 1; /* Allow the content to fill available space */
  display: flex;
  flex-direction: column;
  position: relative; /* Position relative for the diagonal element */
  margin-bottom: 1rem;
  align-content: center;
}

.main-content p {
  font-family: "Space Grotesk Light";
}

.service-content::after {
  content: "";
  display: block; /* Required to give width and height to pseudo-element */
  width: 16px; /* Set the width of the image */
  height: 16px; /* Set the height of the image */
  background-image: url("@/assets/diagonal-element.png");
  background-size: contain; /* This will make sure that your image is scaled to fit */
  background-repeat: no-repeat;
  position: absolute;
  left: 105%;
  right: 0; /* Align to the right */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust vertical centering */
  opacity: var(--service-opacity);
}

@media (max-width: 1400px) {
  .service-content::after {
    display: none;
  }
}

.service-index {
  align-self: center;

  grid-column: index-start / index-end;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Leiko Regular";
  padding-right: 1rem; /* Spacing between index and title */
}

.service-title {
  font-family: "Spartan MB";
  grid-column: title-start / title-end;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0 1rem; /* Adjust spacing as needed */
  font-weight: bold;
}

.service-description {
  grid-column: description-start / description-end;
  margin: 0;
  padding-left: 1rem;
  display: flex; /* Use flexbox to lay out content and pseudo-element */
  align-items: center; /* Align items vertically */
  position: relative; /* Ensure the pseudo-element positions relative to this element */
}

@media (max-width: 1080px) {
  .section-title {
    font-size: 3.5rem; /* Adjust title size for medium screens */
  }

  .service-description {
    font-size: 0.9rem; /* Adjust description text size */
  }
}

@media (max-width: 768px) {
  .service-image {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
  }
  .expertise-container {
    margin: 50px auto; /* Reduce top and bottom margin */
    padding: 1.5rem;
  }

  .service-row {
    opacity: 1;
    flex-direction: row;
  }

  .header-section h2 {
    display: none;
  }

  .section-title {
    font-size: 3rem; /* Adjust title size */
  }

  .services-container {
    grid-template-columns: 1fr; /* Single column layout for services */
    gap: 1rem; /* Reduce gap */
  }

  .service-row {
    display: flex;

    align-items: flex-start; /* Align items to the start */
    margin-bottom: 2rem;
  }

  .service-index {
    font-size: 1.2rem; /* Adjust index font size */
    padding-right: 0.5rem; /* Adjust padding */
    display: flex;
    align-items: center;
    align-content: center;
    padding-top: 2rem;
  }

  .service-title {
    padding-bottom: 0.5rem;
  }

  .service-content::after {
    display: none;
  }

  .service-description {
    font-size: 0.85rem; /* Adjust description text size */
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2rem;
  }
  .service-title {
    font-size: 1.25rem; /* Further reduce title size for very small screens */
  }

  .service-description {
    font-size: 0.8rem; /* Reduce text size for improved readability */
  }
}
</style>
